import React from "react";
import "../stylesheet/Footer.css";
function Footer(){
    return(
<footer >
    <div className="footer">
        <div className="findUs">
            <h1>FIND US</h1>
                <p>
                Address:
                <br />
                29/1, Saraswati Udyog Nagar, Khasra No. 446/1/2, Gram Badhiya Keema,
                <br />
                 Nemawar Road, Indore 452016
                <br /><br />
                Hours:   
                <br />
                 Monday – Saturday
                <br />
                10:00AM–6:00PM
                <br />
                Sunday: Closed
                </p>   
               </div>
             <div className="footer-email" >
                    <h2>
                    Email
                    </h2>
                     <p >
                        dhamejaspicespvtltd@gmail.com
                    </p>
                </div>
            </div>
           
            <div className="footer-bottom">
               
             <p>Copyright © 2024 Dhameja Spices Pvt. Ltd. All rights reserved</p>
         
             <p>Designed & Developed by: Samarth Saini</p>
           </div>
    </footer>
    );
}
export default Footer;