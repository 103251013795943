import React,{useState} from "react";
import ReactCardFlip from 'react-card-flip';
import "../stylesheet/Products.css";
function Products(props){
 
    const [isFlipped, setIsFlipped] = useState(false);

    function handleClick(){
        setIsFlipped(!isFlipped);
    }

    return(
            
		<ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <div className="CardFront">
          <button onClick={handleClick}><img src={props.image} alt="Product" /></button>
        </div>
		<div className="CardBackcontainer">
			<div className="boxSizeContainer">
			<p className="boxSizes">{props.packSize}</p>
			<p className="boxSizes">{props.packSize2}</p>
			</div>
        <div className="CardBack">
          <button onClick={handleClick}> <p>{props.text}</p></button>
        </div>
		</div>
      </ReactCardFlip>


	  
    );
}
export default Products;
