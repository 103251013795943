const images = [
    
    {
      id:1,
      url: "/images/ChanaMasala.jpg",
      title:"Chana Masala  ",
      text1:"Read more >",
      text2:"A Flavorful Spice Blend. Chana masala  , also known as chole masala  , is a traditional Indian spice blend specifically crafted for seasoning chickpea dishes.",
      discription:"Discover our journey in spices. Unmatched quality, trusted globally. We spice up your life with pride.",
      size:"100 Gm",
      size2: "50 Gm",
   },
    {
      id:2,
       url: "/images/ChatMasala.jpg",
       title:"Chat Masala  ",
       text1:"Read more >",
       text2:"Adds a zesty kick to various dishes. Chaat Masala   is a versatile and flavorful spice blend that adds a zesty kick to various dishes. Its a key ingredient in classic Indian street food recipes like papri chaat, pani puri, and bhelpuri",
       discription:"Quality is atmost important for us",
       size:"100 Gm",
       size2: "50 Gm",
      },
    {
      id:3,
       url: "/images/ChickenMasala.jpg",
       title:"Chicken Masala",
       text1:"Read more >",
       text2:"flavorful masala adds depth and richness to your dishes. Chicken masala   has a robust flavor profile, thanks to its blend of aromatic spices. A little goes a long way! You can use just 1 teaspoon of this masala to season any chicken dish.",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"'Spice' as an emotion & not only a word",
    },
    {
      id:4,
       url: "/images/Jeeravan.jpg",
       title:"Jeeravan  ",
       text1:"Read more >",
       text2:"Indore Poha Masala, is a unique spice blend originating from Indore, Madhya Pradesh. Jeeravan Masala is meticulously crafted from more than 20 aromatic spices. Each spice contributes to its distinct flavor profile, making it a versatile addition to various dishes.",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"New World Of Spices",
       content:"We sell fresh and authentic spices from India. .The Spices Land' we collect spices directly from farmers."
    },
    {
      id:5,
       url: "/images/KashmiriMirch.jpg",
       title:"Kashmiri Mirchi  ",
       text1:"Read more >",
       text2:"Kashmiri mirch has a deep wine-red color and a mild flavor. When added to curries, gravies, and marinades, it imparts a beautiful red hue without making the food too spicy. vibrant spice that brings both color and flavor to your culinary creations.",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"Purity & Freshness",
       content:"The high quality of our spices and herbs are simply evident from the fact that they come to you straight from the spice gardens. "
    },
    {
      id:6,
       url: "/images/KasuriMethi.jpg",
       title:"Kasoori Methi",
       text1:"Read more >",
       text2:"Kasuri methi consists of sun-dried fenugreek (methi) leaves. Kasoori Methi, is a magical herb that adds depth and flavor to Indian cuisine.",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"Offering Wide Range of Quality Spices",
    },
    {
      id:7,
       url: "/images/KichenKingMasala.jpg",
       title:"Kitchen King",
       text1:"Read more >",
       text2:"A versatile spice blend that plays a crucial role in Indian cuisine. Kitchen King Masala is like the magician of spices—it can transform ordinary dishes into extraordinary ones. Use it sparingly with any curry to enhance its taste and aroma.",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"Quality is atmost important for us",

    },
    {
      id:8,
       url: "/images/img_1.jpg",
       title:"Coriander  ",
      
       size:"100 Gm",
       size2: "50 Gm",
       discription:"Dhaniya  , is a fragrant and versatile spice...",
       text1:"Read more >",
       text2:"Some studies suggest that coriander may lower heart disease risk factors. It could help reduce high blood pressure and LDL (bad) cholesterol levels. Offering Wide Range of Quality Spices",
    },
    {
      id:9,
       url: "/images/img_2.jpg",
       title:"Turmeric  ",
       text1:"Read more >",
       text2:"Turmeric boosts the body’s antioxidant defenses. derived from the rhizomes of the Curcuma longa plant, is not only a staple in Indian cuisine but also a powerhouse of health benefits. ",
       
       size:"100 Gm",
       size2: "50 Gm",
       discription:"'Spice' as an emotion & not only a word",

    },
    {
      id:10,
       url: "/images/img_3.jpg",
       title:"Red Chilli  ",
       text1:"Read more >",
       text2:"derived from dried and ground red chili peppers. derived from dried and ground red chili peppers, is more than just a fiery spice. It adds a burst of flavor and a touch of heat to your dishes. ",
       size:"100 Gm",
       size2: "50 Gm",
       discription:"Purity & Freshness",
       content:"The high quality of our spices and herbs are simply evident from the fact that they come to you straight from the spice gardens. "
  
    }
 ];
 export default images;