import Header from "./components/Header";
import Mainpage from "./components/Mainpage";
import About from "./components/About";
import Products from "./components/Products";
import "./stylesheet/App.css"
import images from "./stylesheet/images";
import BurgerSlider from "./components/BurgerSlider";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App">
    <Header />
    <BurgerSlider />
    <div  id="Home" className="home-span"></div>
    <Mainpage />
    <div  id="About" className="about-span"></div>
    <About />
    <div  id="Products" className="about_product-span"></div>
    <div className="ProductsHeading"><h1>Products</h1>
    </div>
    
    
    <div className="ProductCardContainer">
    
      {   
        images.map((image)=>(
          <Products 
          image={image.url}
          text={image.text2}
          packSize={image.size}
          packSize2={image.size2}
          />
        ))
      }
   
    </div>
    <Footer />
    </div>
  );
}

export default App;
