import React from "react";
import "../stylesheet/About.css"
function About(){

    return(
        <div>
            <center>
           
                <h1 className="visionHeading">Our Vision & Mission</h1>             </center>
<div>
            <div className="AboutSpices">
            <img src="/images/aboutSpicesImage.png" alt="about spices" />
            <div className="VisionMission">
                <h1>Vision</h1>
                <p>“To enrich every kitchen with the vibrant flavors of Divyadamini Spices, enhancing culinary experiences worldwide.”</p>
                <h1>Mission</h1>
                <p>“We are committed to sourcing the finest ingredients, blending them with care, and delivering authentic spice blends that elevate home-cooked meals. Our passion lies in preserving tradition while embracing innovation.”</p>
            </div>   
            </div>   
            </div> 
        </div>
    );
}
export default About;