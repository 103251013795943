import React from "react";
import { push as Menu } from 'react-burger-menu';
import "../stylesheet/BurgerSlider.css";

function BurgerSlider(){
    return(
        <div id="burgerFront">
        <Menu  isOpen={false} width={ '400px' } right customBurgerIcon={<img style={{height:"100%", width:"100%"}}   src="/images/hamburgerButton.png" alt="button" />} customCrossIcon={<img style={{height:"100%", width:"100%"}} id="burgerBack" src="images/cross.png" alt="cross button" />}>
           
           <span className="bm-menu">
           <h1>LOCATION</h1>
           <a href="https://www.google.com/maps/place/DSPL/@22.6794908,75.9535658,17z/data=!3m1!4b1!4m6!3m5!1s0x3962e36c8da03625:0xcbcd4beb333bb8d1!8m2!3d22.6794908!4d75.9561407!16s%2Fg%2F11swyqhv_p?entry=ttu"><img src="images/location.png" alt="google maps" /> Google Maps </a>
           <h1>FIND US</h1>
           <p>
                Address
                <br />
                29/1, Saraswati Udyog Nagar, Khasra No. 446/1/2, Gram Badhiya Keema, Nemawar Road, Indore 452016
                <br /><br />
                Hours   
                <br />
                 Monday – Saturday
                <br />
                10:00AM–6:00PM
                <br />
                Sunday: Closed
                <br />
                <p className="bm-email">
                Email
                <br />
                dhamejaspicespvtltd@gmail.com
                </p>
           </p>
            </span> 
        </Menu>
        </div>
      
    );
}
export default BurgerSlider;