import React from "react";
import "../stylesheet/Mainpage.css";
function Mainpage(){
    return(
        <div className="maindiv">        
        <h1 className="mainTitle">DivyDamini Spices</h1>
        <hr className="mainTitleLine"/>
        <div className="mainpage"> 
        <img src="/images/img_1.jpg" alt="Coreander Powder"></img>
        <img src="/images/img_2.jpg" alt="Turmeric Powder"></img>
        <img src="/images/img_3.jpg" alt="Chillie Masala"></img>
        <img src="/images/ChatMasala.jpg" alt="Chaat Masala"></img>
        <img src="/images/GaramMasala.jpg" alt="Garam Masala"></img>
            <img src="/images/ChanaMasala.jpg" alt="Chana Masala"></img>
            <img src="/images/ChickenMasala.jpg" alt="Chicken Masala"></img>
            <img src="/images/Jeeravan.jpg" alt="Poha Masala"></img>
            <img src="/images/KashmiriMirch.jpg" alt="Kashmiri Mirch"></img>
            <img src="/images/KasuriMethi.jpg" alt="Kasuri Methi"></img>
            <img src="/images/KichenKingMasala.jpg" alt="Kitchen King Masala"></img>
        </div>
        
        <hr className="mainImageLine"/>
        <h2 className="subTitle">Quality Products By Dhameja Spices Pvt Ltd</h2>
        </div>

    );

}
export default Mainpage;