import React from "react";

import "../stylesheet/Header.css";
function Header(){
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
          });
        }
      };

   return (
      <div>
        <div className="headerBackground"><img src="/images/headerBackground.jpg" alt="background" /></div>
    <div className="navHeader">
         <nav>
            <ul>
                <li><a href="#/" onClick={() => scrollToSection('Home')}><img src="/images/logo1.png" alt="logo" /></a></li>
                 <li><a href="#/" onClick={() => scrollToSection('Home')}>Home</a></li>
                 <li><a href="#/" onClick={() => scrollToSection('About')}>About</a></li>
                 <li><a href="#/" onClick={() => scrollToSection('Products')}>Products</a></li>
                 <li><a href="#/" onClick={() => scrollToSection('Products')}>Contact</a></li>
            </ul>
        </nav>

            
      
    </div>
    </div>
   
    );
}
export default Header;
